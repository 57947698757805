<template>
  <div>
    <b-row>
      <b-col
        sm="12"
      >
        <b-card-code
          title="Actualizar Usuario"
        >
          <b-card-body>
            <FormCreateComponent
              v-if="accessPermissions('users.global.show')"
              :id="id"
            />
          </b-card-body>
        </b-card-code>
      </b-col>
      <b-col
        v-if="accessPermissions('users.global.password.users')"
        sm="12"
      >
        <b-card-code
          title="Cambio de contraseña"
        >
          <b-card-body>
            <FormPasswordComponent
              :id="id"
              root="user"
            />
          </b-card-body>
        </b-card-code>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BRow,
  BCol,
  BCardBody,
} from 'bootstrap-vue'
import FormCreateComponent from './components/FormCreateComponent.vue'
import FormPasswordComponent from '@/pages/dashboard/settings/profile/components/FormPasswordComponent.vue'
import permissions from '@/libs/permissions'

export default {
  name: 'UserEditPage',
  components: {
    BCardCode,
    BRow,
    BCol,
    BCardBody,
    FormCreateComponent,
    FormPasswordComponent,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
  },
}
</script>
